* {
  margin: 0;
  padding: 0;
  min-width: 0;
  min-height: 0;
  outline: none;
  box-sizing: border-box;
  /* transition: all 0.3s ease-in-out; */
  font-family: "Open Sans", sans-serif;
}

html,
body {
  position: relative;
  width: 100%;

  height: 100%;
}

h1 {
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;

  margin: 0;
}
h2 {
  font-size: 30px;
  line-height: 30px;

  margin: 0;
}
h3 {
  font-size: 35px;
  line-height: 35px;
  font-family: "Roboto", sans-serif;
  margin: 0;
}
h4 {
  font-size: 28px;
  line-height: 28px;

  margin: 0;
}
h5 {
  font-size: 24px;
  line-height: 24px;

  margin: 0;
}
h6 {
  font-size: 20px;
  margin: 0;
}
p {
  margin: 0;
  font-size: 16px;
}
span {
  font-size: 16px;
  margin: 0;
}
ul {
  list-style-position: inside;
}
ol {
  list-style-position: inside;
}
li {
}
strong {
}
b {
}

button:focus,
input:focus,
button:active,
input:active {
  outline: none;
}

a:hover {
  text-decoration: none !important;
}

.noResults {
  color: #707070;
}

.container-geral {
  width: 100%;
  display: flex !important;
  position: relative;
  align-items: center;
  max-width: 1920px;
  flex-direction: column;
}

.appCont {
  min-height: 100vh;
  background: #ebebeb;
}

.box {
  width: 100%;

  height: 80vh;
  background: #ffffff;
}
.boxFull {
  width: 100vw;
  height: 95vh;
  background: #ffffff;
}
.boxPrev {
  width: 100vw;
  height: 84vh;
  background: #ffffff;
}

.boxFull .react-flow__node {
  cursor: pointer !important;
}

.boxPrev .react-flow__node {
  cursor: pointer !important;
}
.mapCont {
  margin-top: 20px;
  padding-left: 12px;
}

.mapInner {
  align-items: center;
  justify-content: center;
}

.container-full {
  width: 100%;
  justify-content: center;
  display: flex !important;
}

.boxMenu {
  display: flex;
  position: fixed;
  left: 28px;
  top: 50px;
  z-index: 999;
}

.addMenu {
  display: flex;
  position: fixed;
  left: 28px;
  top: 80%;
  z-index: 999;
}

.downBtn {
  display: flex;
  position: fixed;
  left: 28px;
  top: 86%;
  z-index: 999;
}

.backBtn {
  display: flex;
  position: fixed;
  left: 80px;
  top: 86%;
  z-index: 999;
}

.container-fluid {
  display: flex;
  width: 100%;
  /* max-width: 1234px; */
  position: relative;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.header {
  background-color: #008bd1;
}

#nprogress .spinner-icon {
  border-top-color: #ef8a00;
  border-left-color: #ef8a00;
}

#nprogress .peg {
  box-shadow: 0 0 10px #ef8a00, 0 0 5px #ef8a00;
}

#nprogress .bar {
  background: #ef8a00;
  height: 4px;
}

.headerBox {
  height: 40px;
  flex-direction: row;
  justify-content: flex-end;
}

.blob {
  background: black;
  border-radius: 50%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.white {
  background: #008bd1;
  box-shadow: 0 0 0 0 #008bd1;
  animation: pulse-white 2s infinite;
}

.blobSmall {
  background: black;
  border-radius: 50%;
  width: 20px;
  display: flex;

  justify-content: center;
  align-items: center;
  height: 20px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
}

.blobSmall.blue {
  background: #fff;
  box-shadow: 0 0 0 0 #fff;
  animation: pulse-blue 2s infinite;
}

.blobSmall.white {
  width: 30px;
  height: 30px;
  background: #008bd1;
  box-shadow: 0 0 0 0 #008bd1;
  animation: pulse-white 2s infinite;
}

.depBox {
  padding: 52px 22px;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 700px;
  max-height: 300px;
  overflow: scroll;
}

.editBox {
  padding: 22px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 900px;
}

.depTitle {
  text-align: center;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.depTitle img {
  margin-right: 12px;
}

.depTitle h4 {
  font-size: 28px;
  font-weight: 600;
  color: #4a60a8;
}

.deptext {
  margin-bottom: 50px;
}

.floatUp {
  display: flex;
  position: fixed;
  opacity: 0;
  transition: 0.3s ease-in-out;
  bottom: 50px;
  right: 50px;
  background-color: #1a5eac;
  width: 60px;
  height: 50px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.showUp {
  opacity: 1;
}

.floatUp:hover {
  cursor: pointer;
}

.deptext * {
  font-size: 14px;
  color: #686868;
  text-align: left;
}

.prevFunc {
  width: 100%;
}

.func {
  width: 100%;
  align-items: center;
  max-width: 220px;
  margin: 6px 6px 32px 6px;
}

.funcImg {
  display: flex;
  min-width: 200px;
  max-width: 200px;
  width: 100%;
  align-items: center;
}

.func strong {
  font-size: 16px;
  text-align: center;
  color: #686868;
  font-weight: bold;
}

.funcText b {
  font-size: 16px;
  color: #686868;
  font-weight: 600;
}

.func p {
  text-align: center;
  font-size: 14px;
  color: #686868;
  font-weight: 400;
}

.footer {
  display: flex;
  padding-bottom: 605px;
  background-color: #f1f;

  position: relative;
}

.copyright {
  margin-top: 70px;
  font-size: 14px;
  color: #686867;
  float: right;
}

.footer-center {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  text-align: right;
}

.footer-logos {
  position: absolute;
  left: 0;
  top: 0;
  width: 832px;

  height: 140px;

  padding-top: 38px;
  padding-left: 222px;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #008bd1;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.modalVideo {
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0;
  left: 0;
  transition: all 0.5s;
  background-color: rgba(0, 0, 0, 0.8);
}
.modalVideoBox {
  display: flex;
  width: 100%;
  max-width: 1090px;
  position: relative;
}

.videoClose {
  position: absolute;
  top: 0;
  right: -100px;
  display: flex;
  z-index: 10;
  width: 50px;
  height: 50px;
}
.videoClose:hover {
  cursor: pointer;
}
.videoClose img {
  width: 100%;
}

.modalVideoBox iframe {
  width: 100%;

  height: 556px;
}

.videoPlayHome {
  width: 100%;
  max-width: 760px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;
  z-index: 4;
}

.videoPlayHome:hover img,
.videoPlayHome:focus img {
  transform: scale(0.8);

  cursor: pointer;
}
.videoPlayHome img {
  z-index: 5;
  max-width: 100px;
  transition: all 0.5s;
}

.modelsVideoBg:hover img,
.modelsVideoBg:focus img {
  transform: scale(0.8);
  cursor: pointer;
}

.modelsVideoBg {
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: black; /* fallback color */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;
}

.modelsVideoBg img {
  max-width: 100px;
  transition: all 0.5s;
}

.modelsVideoContainer:hover .modelsVideoBg,
.modelsVideoContainer:focus .modelsVideoBg {
  transform: scale(1.1);
}
.modelsVideoContainer:hover .modelsVideoBg,
.modelsVideoContainer:focus .modelsVideoBg {
  transform: scale(1.1);
}
.modelsVideoBg::before {
  transition: all 0.5s;
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modelsVideoContainer:hover .modelsVideoBg:before,
.modelsVideoContainer:focus .modelsVideoBg:before {
  display: flex;
}
.modelsVideoContainer:hover .modelsVideoBg:before,
.modelsVideoContainer:focus .modelsVideoBg:before {
  display: flex;
}

.HomeBannerVideoContainer:hover .videoPlayHome,
.HomeBannerVideoContainer:focus .videoPlayHome {
  transform: scale(1.1);
}

.btnHover {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btnHover::before {
  width: 0;
  content: "";
  opacity: 0;
  z-index: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  position: absolute;
}

.btnHover:hover::before {
  width: 100%;
  cursor: pointer;
  opacity: 1;
}

.btnHover:hover {
  transform: translateX(4px);
}

.yButton {
  padding: 22px 40px;
  border-radius: 40px;
  color: #ffffff;
  font-weight: 900;
  font-size: 14px;
  background: -webkit-linear-gradient(
    -21deg,
    rgb(255, 163, 36) 0%,
    rgb(255, 191, 37) 100%
  );
}

.yButton:hover {
  color: #ffffff;
}

.yButton img {
  margin-left: 20px;
}

.handle {
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
}

.handle:hover {
  cursor: pointer;
}

.inputColorBox .MuiFormControl-root {
  margin-bottom: 32px;
  width: 100%;
}
.gray {
  width: 10px;
  height: 10px;
  margin-left: 4px;
  background: #707070;
}
.blue {
  width: 10px;
  height: 10px;
  margin-left: 4px;
  background: #77dd76;
}
.orange {
  width: 10px;
  margin-left: 4px;
  height: 10px;
  background: #ff6962;
}

.cBox {
  align-items: center;
  margin-right: 32px;
}

.heightIcon {
  position: absolute;
  top: -20px;
  right: -10px;
}

.heightIconS {
  position: absolute;
  top: -5px;
  right: -5px;
}
.editIcon {
  position: absolute;
  top: -20px;
  left: -10px;
  z-index: 99;
}

.editIcon:hover {
  cursor: pointer;
}

.heightIcon svg {
  transform: rotate(45deg);
}
.editIcon svg {
  width: 15px;
  transform: rotate(270deg);
}

.heightIconS svg {
  transform: rotate(45deg);
}

/* MODAL */

.modal {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  position: fixed;
}

.modalNew {
  background-color: #ffffff;
  z-index: 1050;
  width: 100%;
  position: absolute;
  justify-content: flex-start;
  min-height: 100%;
  top: 0;
  left: 0;
}

.newOrgTop {
  display: flex;
  width: 100%;
  margin-top: 50px;
  padding: 56px 20px;
  max-width: 1000px;
}

.newOrgTop h4 {
  font-size: 32px;
  font-weight: 700;
  color: #1a5eac;
  margin-bottom: 20px;
}

.funcNew {
  width: 100%;
  max-width: 1000px;
  padding: 50px 35px;
  border: 1px solid #476071;
  min-height: 400px;
  margin: 0 0 50px 0;
}

.funcNew h6 {
  font-weight: 600;
  font-size: 20px;
  color: #476071;
}
.funcNew span {
  font-weight: 400;
  font-size: 14px;
  color: #686868;
}
.funcNew span:first-of-type {
  font-size: 16px;
}
.funcNew p {
  font-weight: 400;
  font-size: 14px;
  color: #686868;
}

.funcNew li {
  font-weight: 400;
  font-size: 14px;
  color: #686868;
}

.funcText {
  margin-left: 20px;
}

.funcText p,
.funcText span {
  margin-bottom: 10px;
}

.deptext p,
.deptext span {
  margin-bottom: 10px;
}

.funcText span:first-of-type {
  margin-bottom: 15px;
}

@media (max-width: 1000px) {
  .funcText {
    margin-top: 20px;
    margin-left: 0;
  }
}

.modalNewHeader {
  width: 100%;
  background: linear-gradient(93.58deg, #00a0d9 35.76%, #1a5eac 74.49%);
  padding: 15px;
  position: fixed;
}

.modalInnerHeader {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.headerBack {
  display: flex;
  height: 100%;
  align-items: center;
  width: 50px;
  margin-right: 50px;
}

.headerBack:hover {
  cursor: pointer;
}

.modalNewHeader input {
  width: 100%;
  max-width: 800px;
  border: 0;
  padding: 10px 18px;
  border-radius: 20px;
}

.modalBody {
  width: 100%;
  position: relative;
  height: 100%;
  justify-content: center;
  max-width: 1200px;
}

.modalBody iframe {
  max-width: 780px;
  max-height: 500px;
}

.closeModal {
  position: absolute;
  right: 15px;
  top: 32px;
  width: 50px;
}

.closeModal:hover {
  cursor: pointer;
}

.hidden {
  -webkit-animation: fade-out-bck 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-bck 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-bck {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
}
@keyframes fade-out-bck {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
}

@media (max-width: 1000px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
  span {
    font-size: 16px;
  }
  ul {
  }
  ol {
  }
  li {
  }
  strong {
  }
  b {
  }

  .modalBody iframe {
    max-height: 350px;
  }
  .page404 h1 {
    margin-bottom: 30px;
    margin-right: 0;
  }

  .container-fluid {
    padding: 0 22px;
  }

  .modalVideoBox {
    flex-direction: column;
    align-items: flex-end;
    padding: 0 22px;
  }

  .videoClose {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 12px;
    display: flex;
    z-index: 10;
    width: 30px;
    height: 30px;
  }

  .modalVideoBox iframe {
    width: 100%;

    height: 356px;
  }
  .modalBody {
    min-width: 100%;
  }
}
