.cardBox {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cardBody {
  width: 100%;
  padding: 52px;
  max-width: 780px;
  background: #ebebeb;
  align-items: flex-start;
  -webkit-box-shadow: 5px 7px 50px -12px #008bd1;
  box-shadow: 5px 7px 50px -12px #008bd1;
}

.cardBody .MuiFormControl-root {
  margin-bottom: 12px;
  width: 100%;
}

.cardBody .MuiButtonBase-root {
  margin-top: 22px;
  width: 100%;
  max-width: 250px;
}

.cardContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/images/bg-espaco-digital.jpg);
}

.cardBody h2 {
  margin-bottom: 42px;
  font-weight: 600;
  align-self: center;
}

@media (max-width: 1000px) {
  .cardBody {
    padding: 22px;
  }
}
